import { useTerminalsState } from "States/Terminals"
import { Button } from "components/button/index"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { trpc } from "Utils/trpc"
import { SingleValue } from "react-select"
import { Translate, useTrans } from "translations"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminals } from "api/hooks/useTerminals"
import { useModal } from "Contexts"
import { DiscardModal } from "components/modalContainer"
import { useGlobalAlert } from "States/globalAlert"
import { Toggle } from "components/Toggle"
import { useConfig } from "api/hooks/useConfig"
import { StyledSelect } from "components/StyledSelect"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { useConfigService } from "./useConfigService"
import { remove, sortBy, uniqBy } from "lodash"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/models/Common"
import { useAccessParents } from "pages/infrastructure/functions"
import { getWasteTypeName } from "Utils/commonExportFunctions"
import Skeleton from "react-loading-skeleton"
import timezones from "timezones-list"
import { LanguageType } from "admin-client-server/src/config-api"

type Props = {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const Building: FC<Props> = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
	const { terminals } = useTerminalsState()
	const { updateTerminal, isUpdatingTerminal } = useTerminals()
	const { wasteTypes: sanityWasteTypes, wasteTypeClassificationSystems } = useCommonEntitiesStore()
	const { showModal, hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { isRealEstate } = useConfig()

	const [selectedTerminalId, setSelectedTerminalId] = useState<string | undefined>()
	const { accessParents, isLoading: isLoadingAccessParents } = useAccessParents(selectedTerminalId)
	const {
		terminalWasteTypeConfig,
		terminalConfig,
		refetchTerminalConfig,
		refetchTerminalWasteTypeConfig,
		isLoadingTerminalConfig,
	} = useConfigService({ terminalIdParam: selectedTerminalId, queriesEnabled: isRealEstate })

	const [permanentLogin, setPermanentLogin] = useState<boolean>(false)
	const [language, setLanguage] = useState<LanguageType | null>(null)
	const [wasteTypeClassificationSystemId, setWasteTypeClassificationSystemId] = useState<
		string | null
	>(null)
	const [updatedHandledBySmartInfra, setUpdatedHandledBySmartInfra] = useState<
		{ code: string; handledBySmartInfra: boolean }[]
	>([])
	const [timezone, setTimezone] = useState<string | null>(null)

	const selectedTerminal = useMemo(() => {
		return terminals.find(t => t.id === selectedTerminalId)
	}, [terminals, selectedTerminalId])

	const hasChangedHandledBySmartInfra = useMemo(() => {
		return updatedHandledBySmartInfra.some(
			({ code, handledBySmartInfra }) =>
				terminalWasteTypeConfig.find(twtc => twtc.wasteTypeCode === code)?.handledBySmartInfra !==
				handledBySmartInfra
		)
	}, [updatedHandledBySmartInfra, terminalWasteTypeConfig])

	useEffect(() => {
		const selectedTerminal = terminals.find(t => t.id === selectedTerminalId)
		if (!selectedTerminal) {
			setHasUnsavedChanges(false)
			return
		}

		const hasChangedWTCS =
			selectedTerminal?.wasteTypeClassificationSystemId !== wasteTypeClassificationSystemId
		const hasChangedPermanentLogin = terminalConfig?.allowAppPermanentLogin !== permanentLogin
		const hasChangedTimezone = terminalConfig?.timezone !== timezone
		const hasChangedLanguage = terminalConfig?.language !== language

		const hasChanges =
			hasChangedWTCS ||
			hasChangedPermanentLogin ||
			hasChangedTimezone ||
			hasChangedLanguage ||
			hasChangedHandledBySmartInfra
		setHasUnsavedChanges(hasChanges)
	}, [
		selectedTerminalId,
		setHasUnsavedChanges,
		wasteTypeClassificationSystemId,
		terminals,
		terminalConfig,
		permanentLogin,
		timezone,
		updatedHandledBySmartInfra,
		terminalWasteTypeConfig,
		language,
		hasChangedHandledBySmartInfra,
	])

	const { mutateAsync: updateTerminalConfig, isLoading: isUpdatingAppConfig } =
		trpc.config.updateTerminalConfig.useMutation({
			onSuccess: () => {
				refetchTerminalConfig()
			},
		})

	const {
		mutateAsync: updateTerminalWasteTypeConfig,
		isLoading: isUpdatingTerminalWasteTypeConfig,
	} = trpc.config.updateTerminalWasteTypeConfig.useMutation({
		onSuccess: () => {
			refetchTerminalWasteTypeConfig()
		},
	})

	useEffect(() => {
		if (
			selectedTerminal &&
			terminalConfig &&
			selectedTerminal.id === terminalConfig.terminalUuid &&
			!terminalConfig.wasteTypeClassificationSystemId &&
			!!selectedTerminal.wasteTypeClassificationSystemId
		) {
			updateTerminalConfig({
				terminalId: selectedTerminal.id,
				wasteTypeClassificationSystemId: selectedTerminal.wasteTypeClassificationSystemId,
			})
		}
	}, [selectedTerminal, terminalConfig, updateTerminalConfig])

	const { t } = useTrans()

	const terminalOptions = terminals
		.map(terminal => ({
			label: terminal.name,
			value: terminal.id,
		}))
		.sort((a, b) => sortAlphabeticallyByProperty(a, b, "label"))

	const wasteTypeClassificationSystemOptions = useMemo(() => {
		return (
			wasteTypeClassificationSystems?.map(w => ({
				label: w.name,
				value: w.id,
			})) || []
		)
	}, [wasteTypeClassificationSystems])

	const timezoneOptions = useMemo(
		() =>
			timezones
				.map(t => ({
					label: t.label,
					value: t.tzCode,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[]
	)

	const languageOptions = useMemo(
		() =>
			Object.values(LanguageType)
				.map(code => ({
					label: t(`languages:${code}`),
					value: code,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[t]
	)

	useEffect(() => {
		if (terminalConfig) {
			setPermanentLogin(terminalConfig.allowAppPermanentLogin)
			setTimezone(terminalConfig.timezone)
			setLanguage(terminalConfig.language)
		}
	}, [terminalConfig])

	const containers = useMemo(() => {
		return sortBy(
			uniqBy(
				accessParents.flatMap(ap => ap.containers as ACCESS_POINT[]),
				"wasteType.code"
			),
			"wasteType.code"
		)
	}, [accessParents])

	const onWasteTypeHandledBySmartInfraChange = useCallback(
		(wasteTypeCode: string, checked: boolean) => {
			setUpdatedHandledBySmartInfra(prev => [
				...remove(prev, ({ code }) => code !== wasteTypeCode),
				{
					code: wasteTypeCode,
					handledBySmartInfra: checked,
				},
			])
			setHasUnsavedChanges(true)
		},
		[setUpdatedHandledBySmartInfra, setHasUnsavedChanges]
	)

	const onTerminalChange = useCallback(
		(change: SingleValue<(typeof terminalOptions)[number]>) => {
			const changeTerminal = () => {
				setSelectedTerminalId(change?.value as string)
				setWasteTypeClassificationSystemId(
					terminals.find(t => t.id === change?.value)?.wasteTypeClassificationSystemId || null
				)
				setUpdatedHandledBySmartInfra([])
			}

			if (hasUnsavedChanges) {
				showModal(
					<DiscardModal
						onCancel={() => hideModal()}
						onConfirm={() => {
							changeTerminal()
							hideModal()
						}}
					/>
				)
			} else {
				changeTerminal()
			}
		},
		[terminals, hasUnsavedChanges, showModal, hideModal]
	)

	const onWasteTypeClassificationSystemIdChange = useCallback(
		(change: SingleValue<(typeof wasteTypeClassificationSystemOptions)[number]>) => {
			setWasteTypeClassificationSystemId(change?.value ?? null)
		},
		[setWasteTypeClassificationSystemId]
	)

	const onTimezoneChange = useCallback(
		(change: SingleValue<(typeof timezoneOptions)[number]>) => {
			setTimezone(change?.value as string)
		},
		[setTimezone]
	)

	const onLanguageChange = useCallback(
		(change: SingleValue<(typeof languageOptions)[number]>) => {
			setLanguage(change?.value as LanguageType)
		},
		[setLanguage]
	)

	const findHandledBySmartInfra = useCallback(
		(wasteTypeCode: string) => {
			return (
				updatedHandledBySmartInfra.find(h => h.code === wasteTypeCode) ||
				terminalWasteTypeConfig.find(twtc => twtc.wasteTypeCode === wasteTypeCode)
			)
		},
		[updatedHandledBySmartInfra, terminalWasteTypeConfig]
	)

	const onSave = useCallback(async () => {
		if (!selectedTerminalId) return
		try {
			await updateTerminal({
				id: selectedTerminalId,
				wasteTypeClassificationSystemId: wasteTypeClassificationSystemId ?? undefined,
			})
			await updateTerminalConfig({
				terminalId: selectedTerminalId,
				allowAppPermanentLogin: permanentLogin,
				wasteTypeClassificationSystemId: wasteTypeClassificationSystemId ?? undefined,
				timezone: timezone ?? undefined,
				language: language ?? undefined,
			})

			if (hasChangedHandledBySmartInfra) {
				await updateTerminalWasteTypeConfig({
					terminalId: selectedTerminalId,
					updates: updatedHandledBySmartInfra.map(({ code, handledBySmartInfra }) => ({
						wasteTypeCode: code,
						handledBySmartInfra,
						names: [],
					})),
				})
			}

			setHasUnsavedChanges(false)
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
		} catch (error: Error | any) {
			console.error(error)
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error?.message,
			})
		}
	}, [
		selectedTerminalId,
		updateTerminal,
		wasteTypeClassificationSystemId,
		updateTerminalConfig,
		permanentLogin,
		timezone,
		hasChangedHandledBySmartInfra,
		setHasUnsavedChanges,
		setGlobalAlert,
		updateTerminalWasteTypeConfig,
		updatedHandledBySmartInfra,
		language,
	])

	const isSaving = isUpdatingAppConfig || isUpdatingTerminal || isUpdatingTerminalWasteTypeConfig

	return (
		<div className="space-y-4">
			<div className="my-4">
				<ul className="list-disc list-inside ml-2">
					<Translate i18nKey="hints:configureBuildings" children={[<li />, <li />, <li />]} />
				</ul>
			</div>
			<div className="max-w-[412px]">
				<label className="select-none">{t("configLabels:selectBuilding")}</label>
				<StyledSelect
					className="mb-4 mt-1"
					options={terminalOptions}
					value={terminalOptions.find(o => o.value === selectedTerminalId)}
					onChange={onTerminalChange}
				/>
			</div>
			{selectedTerminal && (
				<>
					<div className="bg-grey1 max-w-[412px] p-4">
						<h3 className="text-xl font-signifier mb-2">{selectedTerminal.name}</h3>
						<div className="max-w-[380px]">
							<label className="select-none">{t("formLabels:setDefaultWTCS")}</label>
							<StyledSelect
								className="mt-2 mb-4"
								options={wasteTypeClassificationSystemOptions}
								value={
									wasteTypeClassificationSystemOptions.find(
										o => o.value === wasteTypeClassificationSystemId
									) ?? null
								}
								onChange={onWasteTypeClassificationSystemIdChange}
							/>
						</div>
						{isLoadingTerminalConfig ? (
							<Skeleton count={3} height={38} className="mb-4" />
						) : (
							<>
								<div className="max-w-[380px]">
									<label className="select-none">{t("formLabels:defaultTimezone")} *</label>
									<StyledSelect
										className="mt-2 mb-4"
										options={timezoneOptions}
										value={timezoneOptions.find(o => o.value === timezone)}
										onChange={onTimezoneChange}
									/>
								</div>
								<div className="max-w-[380px]">
									<label className="select-none">{t("configLabels:buildingLanguage")} *</label>
									<StyledSelect
										className="mt-2 mb-4"
										options={languageOptions}
										value={languageOptions.find(o => o.value === language)}
										onChange={onLanguageChange}
									/>
								</div>
								<div className="mb-4">
									<Toggle
										label={t("configLabels:permanentLogin")}
										checked={permanentLogin}
										onChange={() => setPermanentLogin(!permanentLogin)}
									/>
								</div>
							</>
						)}
					</div>
					<div className="p-4">
						<h3 className="text-xl font-signifier mb-2">
							{t("configLabels:wasteTypesHandledBySmartInfra")}
						</h3>
						<Translate
							i18nKey="hints:configureTwtHandledBySmartInfra"
							children={[<li />, <li />]}
						/>
						<div className="mt-4">
							{isLoadingAccessParents ? (
								<Skeleton count={5} height={30} borderRadius={0} />
							) : (
								containers.map(container => {
									const {
										wasteType: { code, name },
									} = container

									const containerName = getWasteTypeName(sanityWasteTypes || [], code) || name
									const existing = findHandledBySmartInfra(code)

									return (
										<div key={code} className="my-2 flex">
											<Toggle
												checked={existing?.handledBySmartInfra ?? false}
												onChange={checked => onWasteTypeHandledBySmartInfraChange(code, checked)}
											/>
											<span className="pl-4">
												{containerName} ({code})
											</span>
										</div>
									)
								})
							)}
						</div>
					</div>
					<hr className="-mx-4" />
					<Button
						label="actions:save"
						disabled={!hasUnsavedChanges}
						onClick={onSave}
						loading={isSaving}
					/>
				</>
			)}
		</div>
	)
}
