import { useTerminals } from "api/hooks/useTerminals"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"

interface DeleteTerminalModalProps {
	terminalId: string
	terminalName: string
}

export const DeleteTerminalModal: FC<DeleteTerminalModalProps> = ({ terminalId, terminalName }) => {
	const { hideModal } = useModal()
	const { t } = useTrans()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals, terminals } = useTerminals()

	const { mutateAsync: deletePoint, isLoading: isDeletingPoint } =
		trpc.accessPoints.delete.useMutation()

	const { setGlobalAlert } = useGlobalAlert()
	const { setCurrentTerminal } = useTerminalsState()

	const onConfirm = useCallback(() => {
		deletePoint(
			{ id: terminalId, deleteTerminalConfig: true },
			{
				onSuccess: async () => {
					setGlobalAlert({
						type: "success",
						message: "systemMessages:terminalDeleted",
					})
					await refetchTerminals()
					hideModal()

					const newTerminalId = terminals[0]?.id || ""
					setCurrentTerminal(terminals[0])
					navigate(`/infrastructure/manage${newTerminalId && `/${newTerminalId}`}`)
				},
				onError: error => {
					console.error(error)
					setGlobalAlert({
						type: "error",
						message: "errors:unknown",
					})
				},
			}
		)
	}, [
		deletePoint,
		terminalId,
		setGlobalAlert,
		hideModal,
		refetchTerminals,
		terminals,
		setCurrentTerminal,
		navigate,
	])

	return (
		<ModalContainer
			title="actions:deleteTerminal"
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isDeletingPoint || isLoadingTerminals}
			onConfirmText="actions:confirm"
		>
			<div className="pt-3">
				<p>{t("hints:areYouSureDelete", { name: terminalName })}</p>
			</div>
		</ModalContainer>
	)
}
