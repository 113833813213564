import { EmailJobType, LanguageType } from "admin-client-server/src/config-api"
import { useConfig } from "api/hooks/useConfig"
import { useModal } from "Contexts"
import { FC, useCallback } from "react"
import { useTerminalsState } from "States/Terminals"
import { Translate, useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { AddOrUpdateRecipientModal, EntryType } from "./components/addOrUpdateRecipientModal"
import { ListForm } from "./components/listForm"
import { useConfigService } from "./useConfigService"
import { useEmailJob } from "./components/useEmailJob"
import { ConfirmModal } from "components/ConfirmModal"

export const ActionReport: FC = () => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const { config } = useConfig()
	const { terminalConfig } = useConfigService()
	const { currentTerminal } = useTerminalsState()

	const {
		data: emailJobs = [],
		refetch: refetchEmailJobs,
		isLoading: isLoadingEmailJobs,
	} = trpc.config.getTerminalEmailJobsByType.useQuery({
		terminalId: currentTerminal.id,
		type: EmailJobType.ActionReport,
	})

	const {
		addEmailJob,
		updateEmailJob,
		deleteEmailJob,
		isAddingEmailJob,
		isDeletingEmailJob,
		isUpdatingEmailJob,
	} = useEmailJob({
		onSuccessFunc: refetchEmailJobs,
	})

	const addEmailRecipient = useCallback(
		(email: string, language: LanguageType) => {
			addEmailJob({
				terminalId: currentTerminal.id,
				email,
				language,
				type: EmailJobType.ActionReport,
			})
		},
		[addEmailJob, currentTerminal.id]
	)

	const editEmailRecipient = useCallback(
		(email: string, language: LanguageType, id: string) => {
			updateEmailJob({
				emailJobId: id.toString(),
				email,
				language,
			})
		},
		[updateEmailJob]
	)

	const openAddActionReportRecipientModal = useCallback(() => {
		showModal(
			<AddOrUpdateRecipientModal
				modalTitle={t("formLabels:addEmailRecipient")}
				onSave={addEmailRecipient}
				initialValues={{
					language: terminalConfig?.language ?? config.language ?? LanguageType.En,
				}}
				existingRecipients={emailJobs}
			/>
		)
	}, [addEmailRecipient, config.language, showModal, terminalConfig?.language, emailJobs, t])

	const openEditActionReportRecipientModal = useCallback(
		(entry: EntryType) => {
			showModal(
				<AddOrUpdateRecipientModal
					modalTitle={t("formLabels:editEmailRecipient")}
					onSave={editEmailRecipient}
					initialValues={{
						id: entry.id,
						email: entry.email,
						language: entry.language,
					}}
					existingRecipients={emailJobs}
				/>
			)
		},
		[editEmailRecipient, showModal, emailJobs, t]
	)

	const openConfirmDeleteRecipientModal = useCallback(
		(id: string) => {
			showModal(
				<ConfirmModal
					title="formLabels:deleteEmailRecipient"
					hint={t("hints:deleteEmailRecipient")}
					onConfirm={() => deleteEmailJob({ emailJobId: id.toString() })}
				/>
			)
		},
		[showModal, t, deleteEmailJob]
	)

	return (
		<div className="space-y-4">
			<div className="mt-4 max-w-4xl text-sm">
				<ul className="list-disc list-inside ml-2">
					<Translate i18nKey="hints:actionReportRecipient" children={[<li />]} />
				</ul>
			</div>
			<div className="mt-4 max-w-4xl text-sm">
				<div className="mb-2 font-medium">{t("configLabels:recipientsTitle")}</div>
				<ul className="list-disc list-inside ml-2">
					<Translate i18nKey="hints:actionReportHint" children={[<li />]} />
				</ul>
			</div>
			<ListForm
				data={emailJobs}
				title={t("formLabels:recipients")}
				buttonTitle={t("formLabels:addRecipient")}
				onAdd={openAddActionReportRecipientModal}
				onEdit={openEditActionReportRecipientModal}
				onDelete={openConfirmDeleteRecipientModal}
				entryRender={entry => ({
					label: entry.email,
					labelBottom: t(`languages:${entry.language}`),
				})}
				className="max-w-4xl !mb-16"
				disabled={isAddingEmailJob || isUpdatingEmailJob || isDeletingEmailJob}
				loadingData={isLoadingEmailJobs}
				emptyMessage={t("errors:noRecipients")}
			/>
		</div>
	)
}
