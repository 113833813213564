import { MAX_EXPECTED_WASTE_CATEGORIES } from "admin-client-server/src/constants"
import { ConfirmModal } from "components/ConfirmModal"
import { useModal } from "Contexts"
import { FC, useCallback, useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { AddWasteCategoryModal } from "./addWasteCategoryModal"
import { ListForm } from "./listForm"
import { twMerge } from "tailwind-merge"

type Props = {
	wasteCategoriesInConfig?: string[]
	onAddWasteCategory: (expectedWasteTypes: string[]) => void
	onRemoveWasteCategory: (expectedWasteTypes: string[]) => void
	isLoadingData: boolean
	disabled: boolean
	className?: string
	onConfirmAddText?: string
}

export const WasteCategoriesListForm: FC<Props> = ({
	wasteCategoriesInConfig,
	onAddWasteCategory,
	onRemoveWasteCategory,
	isLoadingData,
	disabled,
	className,
	onConfirmAddText,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const { wasteTypeCategories } = useCommonEntitiesStore()

	const wasteCategories = useMemo(() => {
		return (
			(wasteCategoriesInConfig
				?.map(id => {
					const { name } = wasteTypeCategories?.find(w => w.id === id) || {}
					return { id, name }
				})
				.filter(Boolean) as { id: string; name: string }[]) ?? []
		)
	}, [wasteCategoriesInConfig, wasteTypeCategories])

	const openAddWasteCategoryModal = useCallback(() => {
		const options = wasteTypeCategories
			?.map(c => ({
				value: c.id,
				label: c.name,
			}))
			.filter(c => !wasteCategories.some(ec => ec.id === c.value))

		showModal(
			<AddWasteCategoryModal
				onConfirmText={onConfirmAddText}
				onSave={newWasteCategory => {
					const newWasteCategories = [...wasteCategories.map(c => c.id), newWasteCategory]
					onAddWasteCategory(newWasteCategories)
				}}
				options={options}
			/>
		)
	}, [wasteTypeCategories, showModal, onAddWasteCategory, wasteCategories, onConfirmAddText])

	const openConfirmWasteCategoryModal = useCallback(
		(id: string) => {
			showModal(
				<ConfirmModal
					title="formLabels:deleteWasteCategory"
					hint={t("hints:deleteWasteCategory")}
					onConfirm={() => {
						const newWasteCategories = wasteCategories.filter(c => c.id !== id).map(c => c.id)
						onRemoveWasteCategory(newWasteCategories)
					}}
				/>
			)
		},
		[showModal, t, wasteCategories, onRemoveWasteCategory]
	)

	return (
		<ListForm
			data={wasteCategories}
			title={t("statisticsLabels:wasteCategories")}
			buttonTitle={t("formLabels:addWasteCategory")}
			onAdd={openAddWasteCategoryModal}
			onDelete={openConfirmWasteCategoryModal}
			entryRender={entry => ({ label: entry.name })}
			className={twMerge("max-w-4xl", className)}
			disabled={disabled}
			loadingData={isLoadingData}
			emptyMessage={t("errors:noWasteCategories")}
			addButtonDisabled={wasteCategories.length >= MAX_EXPECTED_WASTE_CATEGORIES}
		/>
	)
}
